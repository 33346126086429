import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Contacto } from '../clases/contacto';
import { CookieService } from 'ngx-cookie-service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class ContactoService {

  private contactosURL = 'https://apimirlo.rosettaestudio.net/contacto/all';
  private contactoURL = 'https://apimirlo.rosettaestudio.net/contacto';
  private ctaURL = 'https://apimirlo.rosettaestudio.net/contacto/cta';
  private restauranteURL = 'https://apimirlo.rosettaestudio.net/restaurante';

  private httpOptions = {
    headers: new HttpHeaders({
      'Authorization': 'Bearer ' + this.cookieService.get("admin_token"),
    })
  };

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) { }
    
  getContactos (): Observable<Contacto> {
    return this.http.get<any>(this.contactosURL, this.httpOptions)
  }
  createContactos(producto){
    return this.http.post(this.contactoURL, producto)
  }

  createCta(cta){
    return this.http.post(this.ctaURL, cta)
  }

  reservarRestaurante(reserva){
    return this.http.post(this.restauranteURL, reserva, this.httpOptions)
  }
}
