import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { AuthService } from "../../../utilities/servicios/auth.service";
import { HabitacionService } from "../../../utilities/servicios/habitacion.service";

@Component({
  selector: 'app-edit-caracteristica',
  templateUrl: './edit-caracteristica.component.html',
  styleUrls: ['./edit-caracteristica.component.scss']
})

export class EditCaracteristicaComponent implements OnInit {
  caracteristica: any;
  imagen_destacada;
  logo;
  selectedImagenDestacada = [];
  selectedLogo = [];

  //Para poner la información en la dataTable
  caracteristicaForm = this.fb.group({
    titulo: ['', Validators.required],
    copy: ['', Validators.required],
    is_destacado: [''],
    destacado_url: [''],
    destacado_copy: [''],
    destacado_order: [''],
  })

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private auth: AuthService,
    private elRef: ElementRef,
    private HabitacionService: HabitacionService,
  ) {

  }

  ngOnInit() {
    this.getCaracteristica();
  }

  onFileChanged(event) {
    if (event.target.id == 'fileInputLogo') {
      let i = 0;
      let logo = event.target.files[0];
      this.selectedLogo[0] = logo;

      let logoDIV = this.elRef.nativeElement.querySelector('#logoFoto');
      logoDIV.innerHTML = '';

      logoDIV.insertAdjacentHTML('beforeend', "<mat-card _ngcontent-c10 style='margin: 0px 15px;' id='div_photo_logo_" + i + "' class='div_photo_product card_separator mat-card ng-star-inserted'>" +
        "<mat-card-content _ngcontent-c10 class='mat-card-content' style='text-align: right;'>" +
        "<i _ngcontent-c10 class='fas fa-times remove_related' style='cursor : pointer;' id='borrar_" + i + "'></i>" +
        "<div _ngcontent-c10 class='post_image_especial' style='background-image: url(" + URL.createObjectURL(event.target.files[0]) + ")' readonly='readonly'></div>" +
        "</mat-card-content>" +
        "</mat-card>");

      this.elRef.nativeElement.querySelector('#borrar_' + i).addEventListener('click', this.removeLogo.bind(this, i, false));
    }
    if (event.target.id == 'fileInputImagenDestacada') {
      let i = 0;
      let imagen_destacada = event.target.files[0];
      this.selectedImagenDestacada[0] = imagen_destacada;

      let imagen_destacadaDIV = this.elRef.nativeElement.querySelector('#imagen_destacada');
      imagen_destacadaDIV.innerHTML = '';

      imagen_destacadaDIV.insertAdjacentHTML('beforeend', "<mat-card _ngcontent-c10 style='margin: 0px 15px;' id='div_photo_imagenDestacada_" + i + "' class='card_separator mat-card ng-star-inserted'>" +
        "<mat-card-content _ngcontent-c10 class='mat-card-content' style='text-align: right;'>" +
        "<i _ngcontent-c10 class='fas fa-times remove_related' id='borrar_" + i + "'></i>" +
        "<div _ngcontent-c10 class='post_image_especial' style='background-image: url(" + URL.createObjectURL(event.target.files[0]) + ")' readonly='readonly'></div>" +
        "</mat-card-content>" +
        "</mat-card>");

      this.elRef.nativeElement.querySelector('#borrar_' + i).addEventListener('click', this.removeImagenDestacada.bind(this, i, false));
    }
  }

  removeImagenDestacada(this, imagen_destacada) {
    $('#div_photo_imagenDestacada_0').remove()
    if (imagen_destacada) {
      this.imagen_destacada = ""
    }
    delete this.selectedImagenDestacada[0]
  }

  removeLogo(this, logo) {
    $('#div_photo_logo_0').remove()
    if (logo) {
      this.logo = "";
    }
    delete this.selectedLogo[0]
  }

  getCaracteristica(): void {
    let id = this.route.snapshot.paramMap.get('id');
    this.HabitacionService.getCaracteristica(String(id))
      .subscribe(data => {
        this.caracteristica = data.caracteristica;
        this.logo = data.caracteristica.icono;
        this.imagen_destacada = data.caracteristica.destacado.imagen_destacada;

        this.caracteristicaForm.controls['titulo'].setValue(data.caracteristica.titulo);
        this.caracteristicaForm.controls['copy'].setValue(data.caracteristica.copy);

        this.caracteristicaForm.controls['is_destacado'].setValue(data.caracteristica.destacado.destacado.toString());
        this.caracteristicaForm.controls['destacado_url'].setValue(data.caracteristica.destacado.url);
        this.caracteristicaForm.controls['destacado_copy'].setValue(data.caracteristica.destacado.copy);
        this.caracteristicaForm.controls['destacado_order'].setValue(data.caracteristica.destacado.order);
      });
  }

  onSubmit() {
    var input = new FormData();
    input.append('caracteristica', JSON.stringify(this.caracteristicaForm.value));

    if (this.selectedImagenDestacada.length > 0) {
      this.selectedImagenDestacada.forEach(file => {
        input.append('imagen_destacada', file);
      });
      input.append('length', JSON.stringify(0));
    }

    if (this.selectedLogo.length > 0) {
      this.selectedLogo.forEach(file => {
        input.append('logo', file);
      });
      input.append('length', JSON.stringify(0));
    }

    input.append('logo', JSON.stringify(this.logo));
    input.append('imagen_destacada', JSON.stringify(this.imagen_destacada));

    this.HabitacionService.updateCaracteristica(input, this.route.snapshot.paramMap.get('id')).subscribe(response => {
      this.openSnackBar('Característica modificada correctamente', 'Aceptar');
    }, err => {
      this.openSnackBar('Ha ocurrido un error modificando la característica', 'Aceptar')
      if (err.status == 401) {
        this.auth.logout(true);
      }
    })
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }
}