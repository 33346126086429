import { Component, OnInit, Inject, ElementRef, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';

import { Blog } from '../../../utilities/clases/blog';
import { BlogService } from "../../../utilities/servicios/blog.service";

import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from "../../../utilities/servicios/auth.service";

import { AngularEditorConfig } from '@kolkov/angular-editor';


export interface DialogData {
  mensaje: string;
}

@Component({
  selector: 'app-new-blog',
  templateUrl: './new-blog.component.html',
  styleUrls: ['./new-blog.component.scss']
})
export class NewBlogComponent implements OnInit {
  blog: Blog;
  relateds = [];
  selectedFiles = [];
  elementosDeCategorias = [];
  foto;
  div;
  isBrowser;

  // WYSIWYG
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '25rem',
    minHeight: '5rem',
    placeholder: 'Introducir texto aquí...',
    translate: 'no',
    uploadUrl: 'https://apimirlo.rosettaestudio.net/assets/blog', // if needed
    customClasses: [ // optional
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };


  //Para poner la información en la dataTable
  blogForm = this.fb.group({
    titulo: ['', Validators.required],
    subtitulo: ['', Validators.required],
    html: ['', Validators.required],

    nombre_autor: [''],
    facebook_autor: [''],
    twitter_autor: [''],
    linkedin_autor: [''],

    meta_url: ['', Validators.required],
    meta_description: [''],
    meta_title: [''],
    meta_keyword: [''],

    categorias: []
  })

  constructor(
    private fb: FormBuilder,
    private blogService: BlogService,
    private snackBar: MatSnackBar,
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthService,
    private elRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  numero = 0;

  onFileChanged(event) {
    if (this.isBrowser) {
      for (let i = 0; i < event.target.files.length; i++) {
        this.selectedFiles[this.numero] = event.target.files[i];
        this.div.insertAdjacentHTML('beforeend', "<mat-card _ngcontent-c10 style='margin: 10px 30px;' id='div_photo_product_" + this.numero + "' class='div_photo_product card_separator mat-card ng-star-inserted'>" +
          "<mat-card-content _ngcontent-c10 class='mat-card-content' style='text-align: right;'>" +
          "<i _ngcontent-c10 class='fas fa-times remove_related' style='cursor : pointer;' id='borrar_" + this.numero + "'></i>" +
          "<div _ngcontent-c10 class='post_image_especial' style='background-image: url(" + URL.createObjectURL(event.target.files[i]) + ")' readonly='readonly'></div>" +
          "</mat-card-content>" +
          "</mat-card>");
        this.elRef.nativeElement.querySelector('#borrar_' + this.numero).addEventListener('click', this.removeImage.bind(this, this.numero));
        this.numero++;
      }
    }
  }

  removeImage(this, i) {
    if (this.isBrowser) {
      $('#div_photo_product_' + i).remove()
      delete this.selectedFiles[i]
    }
  }

  ngOnInit() {
    // this.getCategorias();
  }

  // getCategorias() {
  //   this.blogService.getCategorias()
  //     .subscribe(data => {
  //       this.elementosDeCategorias = data.categorias;
  //     });
  // }

  ngAfterViewInit() {
    if (this.isBrowser) {
      this.div = this.elRef.nativeElement.querySelector('#fotos');
    }
  }

  onSubmit() {
    if (this.validateForm()) {
      var input = new FormData();
      input.append('blog', JSON.stringify(this.blogForm.value));
      var auxRelateds = [];
      this.relateds.forEach(related => {
        auxRelateds.push(related._id)
      });
      input.append('relateds', JSON.stringify(auxRelateds));

      if (this.selectedFiles.length > 0) {
        this.selectedFiles.forEach(file => {
          input.append('image[]', file);
        });
      }

      this.blogService.createBlog(input).subscribe(response => {
        this.openSnackBar('Artículo creado correctamente', 'Aceptar');
        this.router.navigate(['admin/blog']);
      }, err => {
        if (this.selectedFiles.length == 0) {
          this.openSnackBar('Tienes que añadir una imagen para crear el artículo', 'Aceptar')
        } else {
          this.openSnackBar('Ha ocurrido un error creando el producto', 'Aceptar')
        }
        if (err.status == 401) {
          this.auth.logout(true);
        }
      })
    } else {
      this.openSnackBar('Ha ocurrido un error modificando el artículo', 'Aceptar')
    }
  }

  validateForm() {
    // stop here if form is invalid
    if (this.blogForm.invalid) {
      this.blogForm.get('titulo').markAsTouched();
      this.blogForm.get('subtitulo').markAsTouched();
      this.blogForm.get('html').markAsTouched();

      this.blogForm.get('nombre_autor').markAsTouched();
      this.blogForm.get('facebook_autor').markAsTouched();
      this.blogForm.get('twitter_autor').markAsTouched();
      this.blogForm.get('linkedin_autor').markAsTouched();

      this.blogForm.get('meta_url').markAsTouched();
      this.blogForm.get('meta_description').markAsTouched();
      this.blogForm.get('meta_title').markAsTouched();
      this.blogForm.get('meta_keyword').markAsTouched();
      return false;
    } else {
      return true;
    }
  }

  removeRelated(related_id) {
    if (this.relateds.find(x => x._id == related_id)) {
      this.relateds.splice(this.relateds.findIndex(x => x._id == related_id), 1);
    }
  }

  addRelated() {
    this.openDialog();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  getRelatedBlog(relatedID) {
    this.blogService.getBlog(String(relatedID))
      .subscribe(data => {
        this.relateds.push(data.blog)
      });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(addRelatedDialogNewBlog, {
      width: '500px',
      data: this.relateds
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.getRelatedBlog(result);
    });
  }
}

export interface RelatedList {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'addRelatedDialogNewBlog',
  templateUrl: '../addRelatedBlogDialog.html',
})

export class addRelatedDialogNewBlog {

  allRelateds;
  selectedBlog: string;

  constructor(
    public dialogRef: MatDialogRef<addRelatedDialogNewBlog>,
    private blogService: BlogService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.blogService.getExceptions(data)
      .subscribe(data => {
        this.allRelateds = data.blogs;
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addRelated() {
    if (this.selectedBlog != undefined) {
      this.closeDialog();
    } else {
      this.openSnackBar('Debes seleccionar un producto', 'Aceptar');
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  closeDialog() {
    this.dialogRef.close(this.selectedBlog);
  }

}