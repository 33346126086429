import { Component, OnInit } from '@angular/core';
import mapStyle from '../contacto/mapStyle.json';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})

export class NotFoundComponent implements OnInit {
  styles = mapStyle;
  zoom = 15;
  defaultLat = 41.41348;
  defaultLong = 2.1346098;
  openedWindow: any; // alternative: array of numbers

  constructor(
    
  ) {
  
  }

  ngOnInit() {

  }
}
