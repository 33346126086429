export class GlobalUtilities {
    constructor() { }

    social_share(id) {
        // var newURL = window.location.protocol + "//" + window.location.host + "/" + window.location.pathname;
        var newURL = window.location.href;

        switch (id) {
            case 1:
                //Facebook
                window.open("https://www.facebook.com/sharer/sharer.php?u="+newURL);
                break;
            case 2:
                //Twitter
                window.open("https://twitter.com/share?url=" + newURL);
                break;
            case 3:
                //Linkedin
                window.open("https://www.linkedin.com/shareArticle?url=" + newURL);
                break;
            default:
        }
    }

    animateCSS(element, animationName, callback) {
        const node = document.querySelector(element)
        node.classList.add('animated', animationName)

        function handleAnimationEnd() {
            node.classList.remove('animated', animationName)
            node.removeEventListener('animationend', handleAnimationEnd)

            if (typeof callback === 'function') callback()
        }

        node.addEventListener('animationend', handleAnimationEnd)
    }

    windowOpen(element){
        window.open(element)
    }
}