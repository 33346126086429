import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { BlogService } from '../utilities/servicios/blog.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { GlobalUtilities } from '../utilities/clases/global_utilities';
import { Meta, Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-detalle-blog',
  templateUrl: './detalle-blog.component.html',
  styleUrls: ['./detalle-blog.component.scss']
})
export class DetalleBlogComponent implements OnInit {
  blog: any = [];
  relacionados: any = [];
  isBrowser;
  titulo;
  noticias: any;
  utilities;
  config;
  GlobalUtilities = new GlobalUtilities();
  noticia: any;

  slideCaracteristicasConfig: any = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    speed: 500,
    infinite: false,
    autoplay: false,
    adaptiveHeight: false,
    dots: false,
    fade: true,
    cssEase: 'linear',
    rows: 0,
    centerMode: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          centerPadding: "0px",
          fade: false,
        }
      },
    ]
  };

  carouselConfig: any = {
    centerMode: true,
    centerPadding: "0px",
    cssEase: "ease",
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    focusOnSelect: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
          centerMode: false,
        }
      },
    ]
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private blogService: BlogService,
    private meta: Meta,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    //Same URL Router Events
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(async (event: NavigationEnd) => {
      this.getBlog();
    });
    //END Same URL Router Events
  }

  ngOnInit() {
    this.getBlog();
    $("#headerForm").addClass('change-color')
  }

  getBlog(): void {
    let titulo = this.route.snapshot.paramMap.get('titulo');
    this.blogService.getBlogByName(String(titulo))
      .subscribe(data => {
        if (data.blog.length > 0 && data.blog[0].activo) {
          this.blog = data.blog[0];
          this.relacionados = data.blog[0].relacionados.filter((items) => items.activo === true);

          this.meta.updateTag({ name: 'og:author', property: 'og:author', content: 'halibut' });
          this.meta.updateTag({ name: 'og:description', property: 'og:description', content: this.blog.meta.meta_description });
          this.meta.updateTag({ name: 'og:type', property: 'og:type', content: 'article' });
          this.meta.updateTag({ name: 'og:title', property: 'og:title', content: this.blog.meta.meta_title });
          this.meta.updateTag({ name: 'og:url', property: 'og:url', content: this.blog.meta.meta_url });
          this.meta.updateTag({ name: 'og:image', property: 'og:image', content: 'https://apimirlo.rosettaestudio.net' + this.blog.photo[0] });

          this.meta.updateTag({ name: 'twitter:card', property: 'twitter:card', content: 'summary' });
          this.meta.updateTag({ name: 'twitter:description', property: 'twitter:description', content: this.blog.meta.meta_description });
          this.meta.updateTag({ name: 'twitter:image', property: 'twitter:image', content: 'https://apimirlo.rosettaestudio.net' + this.blog.photo[0] });
          this.meta.updateTag({ name: 'twitter:url', property: 'twitter:url', content: this.blog.meta.meta_url });
          this.meta.updateTag({ name: 'twitter:title', property: 'twitter:title', content: this.blog.meta.meta_title });
        } else {
          window.location.href = '/404';
        }
      });
  }

  beforeChangeBlog(e) {
    if (this.isBrowser) {
      if (window.innerWidth > 991)
        $("#partialBlog .carouselShell .slick-slide")
          .css('transform', 'scale(0.7)');
    }
  }

  afterChangeBlog(e) {
    if (this.isBrowser) {
      if (window.innerWidth > 991)
        $("#partialBlog .carouselShell .slick-slide")
          .css('transform', 'scale(0.8)');
    }
  }

  // Patch cta primer slide infinite slide
  navigateClonedSlide(habitacion) {
    let nameInRoute = [].slice.call(habitacion.srcElement.classList).slice(-1)[0];
    this.router.navigate([nameInRoute]);
  }

  slickInit(e) {
    const elements = e.event.target.lastChild.firstChild.getElementsByClassName('dynamicCTA');
    for (const element of elements) {
      //fer bind al CTA amb la funció "navigate"
      element.addEventListener('click', this.navigateClonedSlide.bind(this));
    }
  }
  // End patch cta primer slide infinite slide
}
