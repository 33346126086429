import { Component, OnInit } from '@angular/core';
import mapStyle from '../contacto/mapStyle.json';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {
  styles = mapStyle;
  zoom = 15;
  defaultLat = 41.41348;
  defaultLong = 2.1346098;
  openedWindow: any; // alternative: array of numbers

  constructor() { }

  ngOnInit() {
  }

}
