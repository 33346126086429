import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { ContactoService } from '../utilities/servicios/contacto.service';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-carta',
  templateUrl: './carta.component.html',
  styleUrls: ['./carta.component.scss']
})
export class CartaComponent implements OnInit {
  isBrowser;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private router: Router,
    public dialog: MatDialog,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  slides = [
    '/assets/images/default/gastronomiaBG.jpg',
    '/assets/images/default/2.jpg',
    '/assets/images/default/3.jpg',
  ]

  slidePrincipalConfig: any = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    speed: 500,
    infinite: true,
    autoplay: false,
    adaptiveHeight: false,
    dots: false,
    fade: true,
    cssEase: 'linear',
    rows: 0,
  };

  ngOnInit() {

  }

  openReservarRestauranteDialog(titulo, mensaje): void {
    const dialogRef = this.dialog.open(cartaReservarRestauranteDialog, {
      width: '800px',
      hasBackdrop: true,
      panelClass: 'advertenciaPanel'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}

export interface DialogData {
  titulo: string;
  mensaje: string;
}

@Component({
  selector: 'cartaReservarRestauranteDialog',
  templateUrl: './reservarRestauranteDialog.html',
  styleUrls: ['./reservarRestauranteDialog.scss']
})

export class cartaReservarRestauranteDialog {

  constructor(
    public dialogRef: MatDialogRef<cartaReservarRestauranteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private contactoService: ContactoService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
  ) {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    // this.onChanges();
  }

  // Open the picker at this (March 15 2019, 10:30) moment
  public startAt = new Date(2019, 2, 15, 10, 30);

  // Min moment: March 15 2019, 10:30
  public min = new Date(2019, 2, 15, 10, 30);

  // Max moment: March 15 2019, 20:30
  public max = new Date(2019, 2, 15, 22, 30);

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action);
  }

  reservarRestauranteForm = this.fb.group({
    nombre: ['', Validators.required],
    apellidos: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    telefono: ['', [Validators.required, Validators.pattern('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$')]],
    fecha: ['', Validators.required],
    hora: ['', Validators.required],
  })

  validateForm() {
    if (this.reservarRestauranteForm.invalid) {
      this.reservarRestauranteForm.get('nombre').markAsTouched();
      this.reservarRestauranteForm.get('apellidos').markAsTouched();
      this.reservarRestauranteForm.get('email').markAsTouched();
      this.reservarRestauranteForm.get('telefono').markAsTouched();
      this.reservarRestauranteForm.get('fecha').markAsTouched();
      this.reservarRestauranteForm.get('hora').markAsTouched();
      return;
    }
    this.onSubmit()
  }

  get nombre() { return this.reservarRestauranteForm.get('nombre'); }
  get apellidos() { return this.reservarRestauranteForm.get('apellidos'); }
  get email() { return this.reservarRestauranteForm.get('email'); }
  get telefono() { return this.reservarRestauranteForm.get('telefono'); }
  get fecha() { return this.reservarRestauranteForm.get('fecha'); }
  get hora() { return this.reservarRestauranteForm.get('hora'); }

  onSubmit() {
    let fecha: any = new Date(this.reservarRestauranteForm.value.fecha);
    fecha = fecha.getDate() + "/" + (fecha.getMonth() + 1) + "/" + fecha.getFullYear();
    this.reservarRestauranteForm.value.fecha = fecha;

    let hora: any = new Date(this.reservarRestauranteForm.value.hora._d);
    hora = hora.getHours() + ":" + hora.getMinutes();
    this.reservarRestauranteForm.value.hora = hora;

    this.contactoService.reservarRestaurante(this.reservarRestauranteForm.value).subscribe(data => {
      this.openSnackBar("La reserva se ha realizado correctamente", "Aceptar");
      this.closeDialog();
    }, err => {
      this.openSnackBar("No se ha podido completar la reserva", "Aceptar");
    })
  }
}
