import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loginAdminURL = 'https://apimirlo.rosettaestudio.net/admin/login';  // URL to web api
  private loginPublicURL = 'https://apimirlo.rosettaestudio.net/public/login';  // URL to web api
  private registerURL = 'https://apimirlo.rosettaestudio.net/usuario';  // URL to web api
  private checkTokenUrl = 'https://apimirlo.rosettaestudio.net/checkToken'
  isBrowser;

  constructor(
    private http: HttpClient, 
    private cookieService: CookieService,
    private router : Router,
    @Inject(PLATFORM_ID) private platformId, 
  ) { 
    this.isBrowser = isPlatformBrowser(platformId);
  }

  register(payload){
    return this.http.post<any>(this.registerURL, payload)
    .pipe(tap(res => {
      console.log(res.usuario);
      if(res.usuario.token != undefined || res.usuario.username != undefined){
        this.setSession(res.usuario.token, false);
        if(this.isBrowser){
          window.location.reload();
        }
      }
    }))
  }

  login(payload){
    return this.http.post<any>(this.loginPublicURL, payload)
    .pipe(tap(res => {
      if(res.public.token != undefined || res.public.email != undefined){
        this.setSession(res.public.token, false);
        if(this.isBrowser){
          window.location.reload();
        }
      }
    }))
  }

  adminLogin(payload){
    return this.http.post<any>(this.loginAdminURL, payload)
    .pipe(tap(res => {
      if(res.admin.token != undefined || res.admin.email != undefined){
        this.setSession(res.admin.token, true);
      }
    }))
  }

  logout(admin){
    if(admin){
      this.cookieService.delete('admin_token', '/');
      this.cookieService.delete('admin_token', '/admin');
      location.href ="/admin/login";
    } else {
      this.cookieService.delete('public_token', '/');
      this.cookieService.delete('public_token', '/admin');
      location.href ="/";
    }
  }

  private setSession(token, admin) {
    if(admin){
      this.cookieService.delete('admin_token');
      this.cookieService.set('admin_token', token);
    } else {
      this.cookieService.delete('public_token');
      this.cookieService.set('public_token', token);
    }
  }

  async checkSession(admin) {
    var token: any = admin ? this.cookieService.get("admin_token") : this.cookieService.get("public_token");
    if(!token) return false
    var httpOptions = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer '+token,
        })
    }

    return new Promise(resolve => this.http.get<any>(this.checkTokenUrl, httpOptions)
    .subscribe(token => {
        if(token.correct != undefined && token.correct){
          resolve(true);
        } else{
          this.logout(admin);
          resolve(false);
        }
    }, err =>{
      this.logout(admin);
      resolve(false);
    }));
  }
}