import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar } from '@angular/material';
import { HabitacionService } from "../../../utilities/servicios/habitacion.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
export interface DialogData {
  mensaje: string;
}

@Component({
  selector: 'app-listado-caracteristicas',
  templateUrl: './listado-caracteristicas.component.html',
  styleUrls: ['./listado-caracteristicas.component.scss']
})
export class ListadoCaracteristicasComponent implements OnInit {
  //Para poner la información en la dataTable
  dataSource: MatTableDataSource<any>;

  displayedColumns: string[] = ['titulo', 'acciones', 'id'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('exportTable') table: ElementRef

  constructor(
    private HabitacionService: HabitacionService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) {
    this.getCaracteristicas();
  }


  ngOnInit() {

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getCaracteristicas(): void {
    this.HabitacionService.getCaracteristicas()
      .subscribe(data => {
        this.dataSource = new MatTableDataSource(data.caracteristicas);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }

  confirmRemove(id, name) {
    this.openRemoveDialog(id, name);
  }

  openRemoveDialog(id, name): void {
    const dialogRef = this.dialog.open(removeCaracteristicasDialog, {
      width: '500px',
      data: [id, name]
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dataSource = new MatTableDataSource();
        this.getCaracteristicas();
        this.openSnackBar("Se ha eliminado la característica correctamente", "Aceptar")
      }
    });
  }
}

@Component({
  selector: 'removeCaracteristicasDialog',
  templateUrl: './removeDialog.html',
  styleUrls: ['./removeDialog.scss']
})

export class removeCaracteristicasDialog {

  constructor(
    public dialogRef: MatDialogRef<removeCaracteristicasDialog>,
    private HabitacionService: HabitacionService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  delete() {
    this.HabitacionService.deleteCaracteristica(this.data[0])
      .subscribe(data => {
        this.dialogRef.close(true);
      });
  }

  cancel() {
    this.dialogRef.close();
  }
}