import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { AuthService } from "../../../utilities/servicios/auth.service";
import { HabitacionService } from "../../../utilities/servicios/habitacion.service";

@Component({
  selector: 'app-new-servicio',
  templateUrl: './new-servicio.component.html',
  styleUrls: ['./new-servicio.component.scss']
})
export class NewServicioComponent implements OnInit {
  servicio: any;
  logo;
  selectedLogo = [];

  //Para poner la información en la dataTable
  servicioForm = this.fb.group({
    titulo: ['', Validators.required],
    copy: ['', Validators.required],
  })

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private auth: AuthService,
    private elRef: ElementRef,
    private HabitacionService: HabitacionService,
  ) {

  }

  ngOnInit() {

  }

  onFileChanged(event) {
    if (event.target.id == 'fileInputLogo') {
      let i = 0;
      let logo = event.target.files[0];
      this.selectedLogo[0] = logo;

      let logoDIV = this.elRef.nativeElement.querySelector('#logoFoto');
      logoDIV.innerHTML = '';

      logoDIV.insertAdjacentHTML('beforeend', "<mat-card _ngcontent-c10 style='margin: 0px 15px;' id='div_photo_logo_" + i + "' class='div_photo_product card_separator mat-card ng-star-inserted'>" +
        "<mat-card-content _ngcontent-c10 class='mat-card-content' style='text-align: right;'>" +
        "<i _ngcontent-c10 class='fas fa-times remove_related' style='cursor : pointer;' id='borrar_" + i + "'></i>" +
        "<div _ngcontent-c10 class='post_image_especial' style='background-image: url(" + URL.createObjectURL(event.target.files[0]) + ")' readonly='readonly'></div>" +
        "</mat-card-content>" +
        "</mat-card>");

      this.elRef.nativeElement.querySelector('#borrar_' + i).addEventListener('click', this.removeLogo.bind(this, i, false));
    }
  }

  removeLogo(this, logo) {
    $('#div_photo_logo_0').remove()
    if (logo) {
      this.logo = "";
    }
    delete this.selectedLogo[0]
  }

  onSubmit() {
    var input = new FormData();
    input.append('servicio', JSON.stringify(this.servicioForm.value));

    if (this.selectedLogo.length > 0) {
      this.selectedLogo.forEach(file => {
        input.append('logo', file);
      });
      input.append('length', JSON.stringify(0));
    }

    input.append('logo', JSON.stringify(this.logo));

    this.HabitacionService.createServicio(input).subscribe(response => {
      this.openSnackBar('Servicio creado correctamente', 'Aceptar');
    }, err => {
      this.openSnackBar('Ha ocurrido un error creando el servicio', 'Aceptar')
      if (err.status == 401) {
        this.auth.logout(true);
      }
    })
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }
}