import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Componentes Public
import { HomeComponent } from './home/home.component';
import { ContactoComponent } from './contacto/contacto.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { BlogComponent } from './blog/blog.component';
import { DetalleBlogComponent } from './detalle-blog/detalle-blog.component';
import { HabitacionesComponent } from './habitaciones/habitaciones.component';
import { DetalleHabitacionComponent } from './detalle-habitacion/detalle-habitacion.component';
import { HotelComponent } from './hotel/hotel.component';
import { GastronomiaComponent } from './gastronomia/gastronomia.component';
import { CartaComponent } from './carta/carta.component';
import { JardinComponent } from './jardin/jardin.component';

//Componentes Admin
import { AdminLoginComponent } from './admin/login/login.component';
import { AdminHomeComponent } from './admin/home/home.component';
import { AdminContactoComponent } from './admin/contacto/contacto.component';
import { ListadoBlogComponent } from './admin/blog/listado-blog/listado-blog.component';
import { NewBlogComponent } from './admin/blog/new-blog/new-blog.component';
import { EditBlogComponent } from './admin/blog/edit-blog/edit-blog.component';
import { ListadoCaracteristicasComponent } from './admin/caracteristicas/listado-caracteristicas/listado-caracteristicas.component';
import { NewCaracteristicaComponent } from './admin/caracteristicas/new-caracteristica/new-caracteristica.component';
import { EditCaracteristicaComponent } from './admin/caracteristicas/edit-caracteristica/edit-caracteristica.component';
import { EditServicioComponent } from './admin/servicios/edit-servicio/edit-servicio.component';
import { NewServicioComponent } from './admin/servicios/new-servicio/new-servicio.component';
import { ListadoServiciosComponent } from './admin/servicios/listado-servicios/listado-servicios.component';

const routes: Routes = [
  // PUBLIC ROUTES
  { path: '', component: HomeComponent, data: { title: 'Home' } },
  { path: 'home', redirectTo: '/', pathMatch: 'full', data: { title: 'Home' } },
  { path: 'politica-de-privacidad', component: HomeComponent, data: { title: 'Home' } },
  { path: 'politica-de-cookies', component: HomeComponent, data: { title: 'Home' } },
  { path: 'contacto', component: ContactoComponent, data: { title: 'Contacto' } },
  { path: 'contacto/gracias', component: ThankYouComponent, data: { title: 'Gracias por el Contacto' } },
  { path: 'blog', component: BlogComponent, data: { title: 'Nuestro Blog | Halibut' } },
  { path: 'blog/:titulo', component: DetalleBlogComponent, data: { title: 'Noticia ' } },
  { path: 'habitaciones', component: HabitacionesComponent, data: { title: 'Habitaciones' } },
  { path: 'habitacion/:titulo', component: DetalleHabitacionComponent, data: { title: 'Habitacion' } },
  { path: 'hotel', component: HotelComponent, data: { title: 'Hotel' } },
  { path: 'gastronomia', component: GastronomiaComponent, data: { title: 'Gastronomia' } },
  { path: 'carta', component: CartaComponent, data: { title: 'Carta' } },
  { path: 'el-jardin-del-mirlo', component: JardinComponent, data: { title: 'El Jardín del Mirlo' } },

  // ADMIN ROUTES
  { path: 'admin', component: AdminHomeComponent, data: { title: 'Panel de Control' } },
  { path: 'admin/login', component: AdminLoginComponent },
  { path: 'admin/contacto', component: AdminContactoComponent, data: { title: 'Contactos' } },
  { path: 'admin/blog', component: ListadoBlogComponent, data: { title: 'Blog' } },
  { path: 'admin/blog/editar/:id', component: EditBlogComponent, data: { title: 'Blog' } },
  { path: 'admin/blog/nuevo', component: NewBlogComponent, data: { title: 'Blog' } },
  { path: 'admin/caracteristicas', component: ListadoCaracteristicasComponent, data: { title: 'Características' } },
  { path: 'admin/caracteristicas/editar/:id', component: EditCaracteristicaComponent, data: { title: 'Características' } },
  { path: 'admin/caracteristicas/nuevo', component: NewCaracteristicaComponent, data: { title: 'Características' } },
  { path: 'admin/servicios', component: ListadoServiciosComponent, data: { title: 'Servicios' } },
  { path: 'admin/servicios/editar/:id', component: EditServicioComponent, data: { title: 'Servicios' } },
  { path: 'admin/servicios/nuevo', component: NewServicioComponent, data: { title: 'Servicios' } },

  //Error Routes
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }