import { Component, OnInit, Inject, PLATFORM_ID, HostListener } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { BlogService } from '../../utilities/servicios/blog.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
import { ContactoService } from '../../utilities/servicios/contacto.service';
import { Router } from '@angular/router';
import { HabitacionService } from '../../utilities/servicios/habitacion.service';

import textos_legales from '../../textos_legales.json';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  noticias: any;
  textos = textos_legales;
  habitaciones: any = [];

  //Para poner la información en la dataTable
  contactMenuForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    telefono: ['', [Validators.required, Validators.pattern('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$')]],
    mensaje: ['', Validators.required],
    avisolegal: [false, Validators.required],
  })

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private blogService: BlogService,
    private contactoService: ContactoService,
    private fb: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private HabitacionService: HabitacionService,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }
  isBrowser;

  ngOnInit() {
    this.getHabitaciones();
    this.getDestacados();

    $(".lvl0 .firstSpan").hover(
      function () {
        $(".lvl0 .firstSpan").removeClass('activeParent');
        $(".lvl0").addClass('activeLVL');

        if ($(this).hasClass('hasChild')) {
          $(this).addClass('activeParent');
          $(".lvl1").addClass('activeLVL');
        }
      },
    );

    $(".hasChild").hover(
      function () {
        $(".lvl1 .firstSpan").hide();
        $("." + $(this).attr('id')).css('display', 'flex');

        // Si haces hover rapido en distintos elementos no oculta los que no estan activos
        // $("." + $(this).attr('id')).css('display', 'flex')
        // .hide()
        // .fadeIn();
      }
    );

    $(".submenuTrigger").click(function(){
      $(".submenu").fadeOut();

      if(!$(this).hasClass('activeSubmenuTrigger')){
        $(".submenuTrigger").removeClass('activeSubmenuTrigger');
        $(this).addClass('activeSubmenuTrigger');

        $("." + $(this).attr('id')).css('display', 'flex')
        .hide()
        .fadeIn();
      }else{
        $(".submenuTrigger").removeClass('activeSubmenuTrigger');
      }
    })
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    if (this.isBrowser) {
      if (window.innerWidth > 992){
        $(".submenu").hide();
        $(".submenuTrigger").removeClass('activeSubmenuTrigger');
      }
    }
  }

  checkAvailability() {
    let url = "https://app.thebookingbutton.com/properties/hotelboutiquemirlodirect";

    //Añadir control de la fecha de entrada y salida obligatorias

    if ($("input[name=check_in_date]").val()) {
      url += '?check_in_date=' + $("input[name=check_in_date]").val() + '';
    }

    if ($("input[name=check_out_date]").val()) {
      url += '&check_out_date=' + $("input[name=check_out_date]").val() + '';
    }

    if ($("input[name=number_adults]").val()) {
      url += '&number_adults=' + $("input[name=number_adults]").val() + '';
    }

    window.open(url);
  }

  getDestacados(): void {
    this.blogService.destacados()
      .subscribe(data => {
        this.noticias = data.blogs.filter((items) => items.activo === true);
      });
  }

  getHabitaciones(): void {
    this.HabitacionService.getHabitaciones()
      .subscribe(data => {
        this.habitaciones = data.habitaciones;
      })
  }

  showRoom(id) {
    $(".roomslvl2 .item").removeClass('activeParent');
    $("#" + id).addClass('activeParent');

    $(".room").hide();

    $("." + id).css("display", "flex")

    // Si haces hover rapido en distintos elementos no oculta los que no estan activos
    // $("." + id)
    //   .css("display", "flex")
    //   .hide()
    //   .fadeIn();
  }

  navigate(url) {
    this.router.navigate([url]);
    $('.burgerContainer').click();
  }

  navigateToRecipeDetails(name: String) {
    //Add dashes in place of spaces
    let nameInRoute: String = name.split(' ').join('-');
    this.router.navigate([nameInRoute]);
  }

  validateForm() {
    if (!this.contactMenuForm.value.avisolegal) {
      this.openSnackBar("Debes aceptar la política de privacidad y aviso legal.", "Aceptar");
      return;
    }

    if (this.contactMenuForm.invalid) {
      this.contactMenuForm.get('email').markAsTouched();
      this.contactMenuForm.get('telefono').markAsTouched();
      this.contactMenuForm.get('mensaje').markAsTouched();
      this.contactMenuForm.get('avisolegal').markAsTouched();
      return;
    }
    this.onSubmit()
  }

  get email() { return this.contactMenuForm.get('email'); }
  get telefono() { return this.contactMenuForm.get('telefono'); }
  get mensaje() { return this.contactMenuForm.get('mensaje'); }
  get avisolegal() { return this.contactMenuForm.get('avisolegal'); }

  onSubmit() {
    this.contactoService.createContactos(this.contactMenuForm.value).subscribe(data => {
      this.router.navigate(['contacto/gracias'])
      $('.burgerContainer').click();
    }, err => {

    })
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action);
  }

  openDialog(titulo, mensaje): void {
    const dialogRef = this.dialog.open(texto_legal_menu_dialog, {
      width: '800px',
      height: '500px',
      data: { titulo: titulo, mensaje: mensaje },
      hasBackdrop: true,
      panelClass: 'advertenciaPanel'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  openReservarRestauranteDialog(): void {
    const dialogRef = this.dialog.open(menuReservarRestauranteDialog, {
      width: '800px',
      hasBackdrop: true,
      panelClass: 'advertenciaPanel'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}

export interface DialogData {
  titulo: string;
  mensaje: string;
}
@Component({
  selector: 'textoLegalDialog',
  templateUrl: '../../textoLegalDialog.html',
})

export class texto_legal_menu_dialog {

  constructor(
    public dialogRef: MatDialogRef<texto_legal_menu_dialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'menuReservarRestauranteDialog',
  templateUrl: '../../carta/reservarRestauranteDialog.html',
  styleUrls: ['../../carta/reservarRestauranteDialog.scss']
})

export class menuReservarRestauranteDialog {

  constructor(
    public dialogRef: MatDialogRef<menuReservarRestauranteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private contactoService: ContactoService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
  ) {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    // this.onChanges();
  }

  // Open the picker at this (March 15 2019, 10:30) moment
  public startAt = new Date(2019, 2, 15, 10, 30);

  // Min moment: March 15 2019, 10:30
  public min = new Date(2019, 2, 15, 10, 30);

  // Max moment: March 15 2019, 20:30
  public max = new Date(2019, 2, 15, 22, 30);

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action);
  }

  reservarRestauranteForm = this.fb.group({
    nombre: ['', Validators.required],
    apellidos: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    telefono: ['', [Validators.required, Validators.pattern('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$')]],
    fecha: ['', Validators.required],
    hora: ['', Validators.required],
  })

  validateForm() {
    if (this.reservarRestauranteForm.invalid) {
      this.reservarRestauranteForm.get('nombre').markAsTouched();
      this.reservarRestauranteForm.get('apellidos').markAsTouched();
      this.reservarRestauranteForm.get('email').markAsTouched();
      this.reservarRestauranteForm.get('telefono').markAsTouched();
      this.reservarRestauranteForm.get('fecha').markAsTouched();
      this.reservarRestauranteForm.get('hora').markAsTouched();
      return;
    }
    this.onSubmit()
  }

  get nombre() { return this.reservarRestauranteForm.get('nombre'); }
  get apellidos() { return this.reservarRestauranteForm.get('apellidos'); }
  get email() { return this.reservarRestauranteForm.get('email'); }
  get telefono() { return this.reservarRestauranteForm.get('telefono'); }
  get fecha() { return this.reservarRestauranteForm.get('fecha'); }
  get hora() { return this.reservarRestauranteForm.get('hora'); }

  onSubmit() {
    let fecha: any = new Date(this.reservarRestauranteForm.value.fecha);
    fecha = fecha.getDate() + "/" + (fecha.getMonth() + 1) + "/" + fecha.getFullYear();
    this.reservarRestauranteForm.value.fecha = fecha;

    let hora: any = new Date(this.reservarRestauranteForm.value.hora._d);
    hora = hora.getHours() + ":" + hora.getMinutes();
    this.reservarRestauranteForm.value.hora = hora;

    this.contactoService.reservarRestaurante(this.reservarRestauranteForm.value).subscribe(data => {
      this.openSnackBar("La reserva se ha realizado correctamente", "Aceptar");
      this.closeDialog();
    }, err => {
      this.openSnackBar("No se ha podido completar la reserva", "Aceptar");
    })
  }
}
