import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { ContactoService } from '../../utilities/servicios/contacto.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import textos_legales from '../../textos_legales.json';

@Component({
  selector: 'app-partial-contacto',
  templateUrl: './partial-contacto.component.html',
  styleUrls: ['./partial-contacto.component.scss']
})
export class PartialContactoComponent implements OnInit {
  contactoForm = this.fb.group({
    nombreyapellidos: ['', Validators.required],
    email: ['', Validators.required],
    telefono: ['', [Validators.required, Validators.email]],
    consulta: ['', [Validators.required, Validators.pattern('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$')]],
    mensaje: ['', Validators.required],
    avisolegal: [false, Validators.required],
  });
  
  textos = textos_legales;

  constructor(
    private fb: FormBuilder, 
    private contactoService: ContactoService, 
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit() {

  }

  openDialog(titulo, mensaje): void {
    const dialogRef = this.dialog.open(texto_legal_dialog_partial_contacto, {
      width: '600px',
      data: { titulo: titulo, mensaje: mensaje }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  validateForm() {
    this.onSubmit()
  }

  onSubmit() {
    this.contactoService.createContactos(this.contactoForm.value).subscribe(data => {
      this.router.navigate(['contacto/gracias'])
    }, err => {

    })
  }
  get nombreyapellidos() { return this.contactoForm.get('nombreyapellidos'); }
  get email() { return this.contactoForm.get('email'); }
  get telefono() { return this.contactoForm.get('telefono'); }
  get consulta() { return this.contactoForm.get('consulta'); }
  get mensaje() { return this.contactoForm.get('mensaje'); }
  get avisolegal() { return this.contactoForm.get('avisolegal'); }

}

export interface DialogData {
  titulo: string;
  mensaje: string;
}
@Component({
  selector: 'textoLegalDialog',
  templateUrl: '../../textoLegalDialog.html',
})

export class texto_legal_dialog_partial_contacto {

  constructor(
    public dialogRef: MatDialogRef<texto_legal_dialog_partial_contacto>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  closeDialog() {
    this.dialogRef.close();
  }

}
