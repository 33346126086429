import { Component, OnInit, Renderer2, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HabitacionService } from '../../utilities/servicios/habitacion.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-partial-habitaciones',
  templateUrl: './partial-habitaciones.component.html',
  styleUrls: ['./partial-habitaciones.component.scss']
})
export class PartialHabitacionesComponent implements OnInit {
  isBrowser;
  habitaciones: any = [];

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private HabitacionService: HabitacionService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    //Same URL Router Events
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(async (event: NavigationEnd) => {
      this.getHabitaciones();

      if(this.router.url == "/habitaciones" || this.router.url == "/el-jardin-del-mirlo"){
        $("#habitaciones").addClass('roomsPage');
        $("#habitaciones").addClass('whiteChange');
      }

      if(this.router.url.indexOf('/habitacion/') > -1)
        $("#habitaciones").addClass('roomDetailPage');
    });
    //END Same URL Router Events
  }

  carouselConfig: any = {
    centerMode: true,
    centerPadding: "0px",
    cssEase: "ease",
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    focusOnSelect: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
          centerMode: false,
        }
      },
    ]
  };

  slidePrincipalConfig: any = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    speed: 500,
    infinite: true,
    autoplay: true,
    adaptiveHeight: false,
    dots: false,
    fade: true,
    cssEase: 'linear',
    rows: 0,
  };

  ngOnInit() {
    this.getHabitaciones();
  }

  getHabitaciones(): void {
    this.HabitacionService.getHabitaciones()
      .subscribe(data => {
        let titulo = this.route.snapshot.paramMap.get('titulo');
        this.habitaciones = data.habitaciones.filter((items) => items.url != titulo);
      })
  }

  beforeChangeRooms(e) {
    if (this.isBrowser) {
      if (window.innerWidth > 991)
        $("#habitaciones .carouselShell .slick-slide")
          .css('transform', 'scale(0.7)');
    }
  }

  // Patch cta primer slide infinite slide
  navigateClonedSlide(habitacion) {
    let nameInRoute = [].slice.call(habitacion.srcElement.classList).slice(-1)[0];
    this.router.navigate([nameInRoute]);
  }

  slickInit(e) {
    const elements = e.event.target.lastChild.firstChild.getElementsByClassName('dynamicCTA');
    for (const element of elements) {
      //fer bind al CTA amb la funció "navigate"
      element.addEventListener('click', this.navigateClonedSlide.bind(this));
    }
  }
  // End patch cta primer slide infinite slide

  afterChangeRooms(e) {
    if (this.isBrowser) {
      if (window.innerWidth > 991)
        $("#habitaciones .carouselShell .slick-slide")
          .css('transform', 'scale(0.8)');
    }
  }

}
