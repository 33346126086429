import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
import { ContactoService } from '../../utilities/servicios/contacto.service';
import { Router } from '@angular/router';
import textos_legales from '../../textos_legales.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  textos = textos_legales;

  //Para poner la información en la dataTable
  footerForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    telefono: ['', [Validators.required, Validators.pattern('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$')]],
    mensaje: ['', Validators.required],
    avisolegal: [false, Validators.required],
  })

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private contactoService: ContactoService,
    private renderer: Renderer2,
    private router: Router,
    private snackBar: MatSnackBar,
  ) {

  }

  ngOnInit() {

  }

  checkAvailability() {
    let url = "https://app.thebookingbutton.com/properties/hotelboutiquemirlodirect";

    //Añadir control de la fecha de entrada y salida obligatorias

    // if ($("input[name=check_in_date]").val()) {
    //   url += '?check_in_date=' + $("input[name=check_in_date]").val() + '';
    // }

    // if ($("input[name=check_out_date]").val()) {
    //   url += '&check_out_date=' + $("input[name=check_out_date]").val() + '';
    // }

    // if ($("input[name=number_adults]").val()) {
    //   url += '&number_adults=' + $("input[name=number_adults]").val() + '';
    // }

    window.open(url);
  }

  //Detectar si un elemento se encuentra visible en la pantalla
  public onIntersection({ target, visible }: { target: Element; visible: boolean }): void {
    this.renderer.addClass(target, visible ? 'active' : 'inactive');
    this.renderer.removeClass(target, visible ? 'inactive' : 'active');

    if ($("#footer").hasClass('active')) {
      $("#burgerIcon").hide();
      $("#scrollUp").fadeIn();
    } else {
      $("#burgerIcon").fadeIn();
      $("#scrollUp").hide();
    }
  };

  validateForm() {
    if (!this.footerForm.value.avisolegal) {
      this.openSnackBar("Debes aceptar la política de privacidad y aviso legal.", "Aceptar");
      return;
    }

    if (this.footerForm.invalid) {
      this.footerForm.get('email').markAsTouched();
      this.footerForm.get('telefono').markAsTouched();
      this.footerForm.get('mensaje').markAsTouched();
      this.footerForm.get('avisolegal').markAsTouched();
      return;
    }
    this.onSubmit()
  }

  get email() { return this.footerForm.get('email'); }
  get telefono() { return this.footerForm.get('telefono'); }
  get mensaje() { return this.footerForm.get('mensaje'); }
  get avisolegal() { return this.footerForm.get('avisolegal'); }

  onSubmit() {
    this.contactoService.createContactos(this.footerForm.value).subscribe(data => {
      this.router.navigate(['contacto/gracias'])
    }, err => {

    })
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action);
  }

  openDialog(titulo, mensaje): void {
    const dialogRef = this.dialog.open(texto_legal_dialog, {
      width: '800px',
      height: '500px',
      data: { titulo: titulo, mensaje: mensaje },
      hasBackdrop: true,
      panelClass: 'advertenciaPanel'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}

export interface DialogData {
  titulo: string;
  mensaje: string;
}
@Component({
  selector: 'textoLegalDialog',
  templateUrl: '../../textoLegalDialog.html',
})

export class texto_legal_dialog {

  constructor(
    public dialogRef: MatDialogRef<texto_legal_dialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}