import { Component, OnInit, Inject, ElementRef, PLATFORM_ID, HostListener } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LazyLoadScriptService } from '../../utilities/servicios/lazy-load-script.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isBrowser;

  monthNames = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN",
    "JUL", "AGO", "SEP", "OCT", "NOV", "DEC"
  ];

  checkIn;
  checkOut;

  checkInDay;
  checkInMonth;
  checkOutDay;
  checkOutMonth;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private router: Router,
    private route: ActivatedRoute,
    private lazyLoadService: LazyLoadScriptService,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    //Same URL Router Events
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(async (event: NavigationEnd) => {
      this.setLogo();
    });
    //END Same URL Router Events
  }

  ngOnInit() {
    let today = new Date();
    today.setDate(today.getDate());
    this.checkIn = today.toISOString().substr(0, 10);;
    this.checkInDay = today.getDate();
    this.checkInMonth = this.monthNames[today.getMonth()];
    $("#check_in_date").val(this.checkIn)

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.checkOut = tomorrow.toISOString().substr(0, 10);
    this.checkOutDay = tomorrow.getDate();
    this.checkOutMonth = this.monthNames[today.getMonth()];
    $("#check_out_date").val(this.checkOut)

    //Burger Scroll Change Color
    $(window).scroll(function () {
      var fixed = $("#headerForm");

      var fixed_position = $("#headerForm").offset().top;
      var fixed_height = $("#headerForm").height();

      var addClass = false;
      $('.whiteChange').each(function () {

        var toCross_position = $(this).offset().top;
        var toCross_height = $(this).height();

        if (fixed_position + fixed_height < toCross_position) {
          //fixed.removeClass('white');
        } else if (fixed_position > toCross_position + toCross_height) {
          //fixed.removeClass('white');
        } else {
          addClass = true;
        }

        if (addClass == true) {
          fixed.addClass('change-color');
        } else {
          fixed.removeClass('change-color');
        }
      }); 
    });
  }

  ngAfterContentInit() {
    this.setLogo();

    this.lazyLoadService.loadScript('https://widget.siteminder.com/ibe.min.js')
      .subscribe(async _ => {

      });
  }

  changeCalendar(element, event) {
    var date = new Date(event);
    date.setDate(date.getDate());

    if (element == 'out') {
      this.checkOutDay = date.getDate();
      this.checkOutMonth = this.monthNames[date.getMonth()];
    }

    if (element == 'in') {
      this.checkInDay = date.getDate();
      this.checkInMonth = this.monthNames[date.getMonth()];
    }
  }

  change_date(element, type) {
    switch (type) {
      case '+':
        if (element == 'out') {
          var date = new Date(this.checkOut);
          date.setDate(date.getDate() + 1);
          this.checkOut = date.toISOString().substr(0, 10);
          this.checkOutDay = date.getDate();
          this.checkOutMonth = this.monthNames[date.getMonth()];
        }

        if (element == 'in') {
          var date = new Date(this.checkIn);
          date.setDate(date.getDate() + 1);
          this.checkIn = date.toISOString().substr(0, 10);
          this.checkInDay = date.getDate();
          this.checkInMonth = this.monthNames[date.getMonth()];
        }

        break;
      case '-':
        if (element == 'out') {
          var date = new Date(this.checkOut);
          date.setDate(date.getDate() - 1);
          this.checkOut = date.toISOString().substr(0, 10);
          this.checkOutDay = date.getDate();
          this.checkOutMonth = this.monthNames[date.getMonth()];
        }

        if (element == 'in') {
          var date = new Date(this.checkIn);
          date.setDate(date.getDate() - 1);
          this.checkIn = date.toISOString().substr(0, 10);
          this.checkInDay = date.getDate();
          this.checkInMonth = this.monthNames[date.getMonth()];
        }

        break;
      default:
        break;
    }
  }

  checkAvailability() {
    let url = "https://app.thebookingbutton.com/properties/hotelboutiquemirlodirect";

    console.log(this.checkIn);
    console.log(this.checkOut);

    if (this.checkIn) {
      if(this.checkIn.toString().length > 15){
        var date = new Date(this.checkIn);
        date.setDate(date.getDate() + 1);
        this.checkIn = date.toISOString().substr(0, 10);
      }
      url += '?check_in_date=' + this.checkIn + '';
    }

    if (this.checkOut) {
      if(this.checkOut.toString().length > 15){
        var date = new Date(this.checkOut);
        date.setDate(date.getDate() + 1);
        this.checkOut = date.toISOString().substr(0, 10);
      }
      url += '&check_out_date=' + this.checkOut + '';
    }

    if ($("input[name=number_adults]").val()) {
      url += '&number_adults=' + $("input[name=number_adults]").val() + '';
    }

    /* window.open(url); */
  }

  setLogo() {
    if ($(".burgerContainer").hasClass('active')) {

    } else {
      if (
        (this.router.url != "/habitaciones" &&
          this.router.url.includes("/habitacion")) || this.router.url.includes("/blog")
      ) {
        $("#logoHeader").hide();
        $("#logoHeaderN").show();
      } else {
        $("#logoHeaderN").hide();
        $("#logoHeader").show();
      }
    }
  }

  showMenu() {
    if (this.isBrowser) {
      if ($("#footer").hasClass('active')) {

      } else {
        if ($(".burgerContainer").hasClass('active')) {
          $(".burgerContainer").removeClass('active');
          $("#headerForm").removeClass('active');
          this.setLogo();

          $('header').css('width', '100%');
          $('#menu').fadeOut();

          // $('.lvl1 .firstSpan').hide();
          // $(".lvl0 .firstSpan").removeClass('activeParent');

          if ($("#footer").hasClass('active')) {

          } else {
            $("#scrollUp").hide();
            $("#closeIcon").hide();
            $("#burgerIcon").fadeIn();
          }

          $('body').css('overflow-y', 'scroll');
        } else {
          $(".burgerContainer").addClass('active');
          $("#headerForm").addClass('active');
          $("#logoHeader").hide();
          $("#logoHeaderN").hide();

          $('header').css('width', 'fit-content');
          $('#menu').css("display", "flex")
            .hide()
            .fadeIn();

          $("#burgerIcon").hide();
          $("#scrollUp").hide();
          $("#closeIcon").fadeIn();

          $('body').css('overflow-y', 'hidden');
        }
      }
    }
  }
}