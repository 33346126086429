import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})

export class HabitacionService {
  private habitacionsURL = 'https://apimirlo.rosettaestudio.net/habitacion/all';
  private habitacionURL = 'https://apimirlo.rosettaestudio.net/habitacion';
  private habitacionURLbyID = 'https://apimirlo.rosettaestudio.net/habitacion/id';
  private caracteristicaURL = 'https://apimirlo.rosettaestudio.net/habitacion/caracteristica';
  private caracteristicasURL = 'https://apimirlo.rosettaestudio.net/habitacion/caracteristicas';
  private servicioURL = 'https://apimirlo.rosettaestudio.net/habitacion/servicio';
  private serviciosURL = 'https://apimirlo.rosettaestudio.net/habitacion/servicios';

  private httpOptions = {
    headers: new HttpHeaders({
      'Authorization': 'Bearer ' + this.cookieService.get("admin_token"),
    })
  };

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) {

  }

  //Crear habitacion.
  createHabitacion(payload) {
    return this.http.post(this.habitacionURL, payload, this.httpOptions)
  }

  //Actualizar habitacion.
  updateHabitacion(payload, id) {
    return this.http.put(this.habitacionURLbyID + "/" + id, payload, this.httpOptions)
  }

  //Recupera todas las habitaciones.
  getHabitaciones() {
    return this.http.get<any>(this.habitacionsURL)
  }

  //Recupera una habitacion por su id.
  getHabitacion(id: String): Observable<any> {
    const url = `${this.habitacionURLbyID}/${id}`;
    return this.http.get<any>(url, {
      withCredentials: true
    })
  }

  //Recupera una habitacion por su nombre.
  getHabitacionByName(nombre: String): Observable<any> {
    const url = `${this.habitacionURL}/${nombre}`;
    return this.http.get<any>(url, {
      withCredentials: true
    })
  }

  //Recupera las caracteristicas de lass habitaciones.
  getCaracteristicas() {
    return this.http.get<any>(this.caracteristicasURL)
  }

  getCaracteristica(id) {
    return this.http.get<any>(this.caracteristicaURL + "/" + id)
  }

  //Recupera las habitaciones filtrados por caracteristicas.
  getHabitacionesByCaracteristicas(caracteristica) {
    return this.http.get<any>(this.caracteristicasURL + caracteristica)
  }

  //Recupera las habitaciones filtrados por caracteristicas.
  getCaracteristicasDestacadas() {
    return this.http.get<any>(this.caracteristicasURL + "/destacadas")
  }

  updateCaracteristica(payload, id) {
    return this.http.put(this.caracteristicaURL + "/" + id, payload, this.httpOptions)
  }

  createCaracteristica(payload) {
    return this.http.post(this.caracteristicaURL, payload, this.httpOptions)
  }

  deleteCaracteristica(id) {
    return this.http.delete<any>(this.caracteristicaURL + "/" + id, this.httpOptions)
  }

  //Recupera los servicios de lass habitaciones.
  getServicios() {
    return this.http.get<any>(this.serviciosURL)
  }

  getServicio(id) {
    return this.http.get<any>(this.servicioURL + "/" + id)
  }

  //Recupera las habitaciones filtrados por servicios.
  getHabitacionesByServicios(servicio) {
    return this.http.get<any>(this.serviciosURL + servicio)
  }

  updateServicio(payload, id) {
    return this.http.put(this.servicioURL + "/" + id, payload, this.httpOptions)
  }

  createServicio(payload) {
    return this.http.post(this.servicioURL, payload, this.httpOptions)
  }

  deleteServicio(id) {
    return this.http.delete<any>(this.servicioURL + "/" + id, this.httpOptions)
  }
}
